@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/Dongle-Regular.ttf);
}
@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/Dongle-Light.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", sans-serif;

$color-1: #546fe4;
$color-2: #212040;
$color-3: rgb(100, 100, 100);
$black: #060d2c;
$white: rgb(238, 238, 238);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-1;
}

body {
  background: $color-2;
  color: $white;
}
li {
  list-style: none;
}
.result {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
