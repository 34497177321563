.card {
  flex: 1 0 25%;
  min-width: 260px;
  max-width: 280px;
  height: 620px;
  background: #060d2c;
  border-radius: 35px;
  margin: 5px;
  padding: 26px 15px 30px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    height: 90px;
    width: 100%;
    background: linear-gradient(180deg, rgba(195, 34, 34, 0) 0%, $black 55%);
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 0 0 30px 30px;
  }

  img {
    width: 80%;
    height: 254px;
    object-fit: cover;
    object-position: top;
    display: block;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0 8px 1px rgba(255, 255, 255, 0.1),
      0 16px 1px rgba(255, 255, 255, 0.05);
  }
  h2 {
    margin: 24px 0 0px;
    letter-spacing: 1px;
    line-height: 20px;
  }
  h5 {
    color: $color-3;
    letter-spacing: 1px;
    font-size: 1.2rem;
    font-weight: 300;
  }
  h4 {
    letter-spacing: 1px;
    font-size: 1.2rem;
    margin-top: -4px;

    span {
      font-size: 0.7rem;
    }
  }
  ul {
    margin: 6px 0;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    background: #212040;
    border-radius: 18px;
    padding: 0px 7px;
    margin: 0 4px 4px 0;
    font-size: 1.1rem;

    &:first-child {
      margin-left: -4px;
    }
  }
  h3 {
    letter-spacing: 1px;
    font-size: 1.4rem;
    margin-top: 10px;
  }
  p {
    color: $color-3;
    font-size: 1.3rem;
    line-height: 20px;
    height: 160px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .date-note-fav{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn {
    background: #546fe4;
    transform: translateX(-50%);
    padding: 5px 5px 0;
    text-align: center;
    font-size: 18px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.2s;
    z-index: 50;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;

    &:hover {
      background: #212040;
      box-shadow: 0 0 10px #546ee4d2;
      font-size: 1.025rem;
    }
  }
}
